<template>
  <div class="container">
    <section class="insider-tip-summary">
      <figure v-if="summary.media" class="image-section">
        <img class="image" :src="summary.media.url" alt="While in Town: tips" />
      </figure>
      <div class="description" v-html="summary.description" />

      <dl v-if="summary.details.length" class="details">
        <div v-for="d in summary.details" :key="`summary-${summary.id}-details-${d.name}`" class="details-item">
          <dt class="details-item__name" v-html="d.name" />
          <dd class="details-item__value" v-html="d.value" />
        </div>
      </dl>
    </section>
  </div>
</template>

<script>
export default {
  name: 'DestinationInsiderTipSummaryTab',
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.insider-tip-summary {
  max-width: 992px;
  margin: auto;
  padding: 16px 0 70px;

  @media (min-width: 768px) {
    padding-top: 70px;
  }

  .image-section {
    margin-bottom: 32px;
  }

  .image {
    width: 100%;
    height: 44vw;
    object-fit: cover;

    @media (min-width: 577px) {
      height: 300px;
    }
  }

  .description {
    font-size: 16px;
    line-height: 1.5;

    ::v-deep {
      p {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .details {
    margin-top: 16px;
  }

  .details-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 32px 0;
    font-size: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid #dadcde;
    }

    @media (min-width: 577px) {
      flex-direction: row;
      gap: 32px;
      padding: 40px 0;
    }

    &__name {
      width: 60px;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    &__value {
      flex: 1;
      line-height: 1.5;

      ::v-deep {
        p {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
